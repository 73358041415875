/**
 * Returns the previous Monday relative to the given date.
 *
 * @param {Date} date - The date for which to find the previous Monday. Defaults to the current date.
 * @return {Date} The previous Monday as a Date object.
 */
export function getPreviousMonday(date: Date = new Date()): Date {
  const previousMonday = new Date(date);
  const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.
  const distanceFromMonday = dayOfWeek === 1 ? 7 : (dayOfWeek + 6) % 7; // Calculate how many days to subtract to get to the previous Monday
  previousMonday.setDate(date.getDate() - distanceFromMonday);
  previousMonday.setHours(0, 0, 0, 0); // Reset time to the start of the day
  return previousMonday;
}

/**
 * Generates the next Monday date based on the given date.
 *
 * @param {Date} [date=new Date()] - The date to calculate the next Monday from. Defaults to the current date.
 * @return {Date} The next Monday date.
 */
export function getNextMonday(date: Date = new Date()): Date {
  const nextMonday = new Date(date);
  const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.
  const daysUntilNextMonday = dayOfWeek === 1 ? 7 : (8 - dayOfWeek) % 7;
  nextMonday.setDate(date.getDate() + daysUntilNextMonday);
  nextMonday.setHours(0, 0, 0, 0);
  return nextMonday;
}

export function getMondayOfDate(date: Date = new Date()) {
  const thisWeekMonday = isMonday(date) ? new Date(date) : getPreviousMonday(date);
  thisWeekMonday.setHours(0, 0, 0, 0);
  return thisWeekMonday;
}

export function getWeekRelative(date: Date) {
  const mondayOfDate = getMondayOfDate(date);
  const mondayOfThisWeek = getMondayOfDate();

  if (mondayOfDate.getTime() === mondayOfThisWeek.getTime()) {
    return "Current";
  }
  const nextMonday = getNextMonday().getTime();
  if (mondayOfDate.getTime() === nextMonday) {
    return "Next";
  }

  const previousMonday = getPreviousMonday(mondayOfThisWeek);
  if (mondayOfDate.getTime() === previousMonday.getTime()) {
    return "Previous";
  }
  return "Other";
}

export function isPastWeek(date: Date) {
  const thisWeekMonday = getMondayOfDate();
  return date.getTime() < thisWeekMonday.getTime();
}

export type RelativeWeek = ReturnType<typeof getWeekRelative>;

export function dateToString(date: Date) {
  return date.toISOString().slice(0, 10);
}

export function stringToDate(date: string) {
  return new Date(`${date ?? ""}T00:00:00`);
}

export function dateToPrettyWeek(date: Date) {
  return date.toLocaleString("en-US", { month: "short", day: "numeric" });
}

export function isMonday(date: Date) {
  return date.getDay() === 1;
}

export function dateToWeekTitle(date: Date, short: boolean = false) {
  const output: { [key in RelativeWeek]: () => string } = {
    Current: () => "This Week",
    Next: () => "Next Week",
    Previous: () => "Previous Week",
    Other: () => (short ? dateToPrettyWeek(date) : `Week of ${dateToPrettyWeek(date)}`),
  };
  return output[getWeekRelative(date)]();
}
